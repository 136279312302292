import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.css";

import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const container = document.getElementById("accommodationsCarousel");

const options = {
    transition: 'slide',
    infinite: false,
    Dots: false,
    Navigation: {
        nextTpl: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" class="!w-14 !h-14 !fill-white"><path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm42.83-94.83a4,4,0,0,1,0,5.66l-32,32a4,4,0,0,1-5.66-5.66L158.34,132H88a4,4,0,0,1,0-8h70.34L133.17,98.83a4,4,0,0,1,5.66-5.66Z"></path></svg>',
        prevTpl: '<svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 256 256" class="!w-14 !h-14 !fill-white"><path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H97.66l25.17,25.17a4,4,0,0,1-5.66,5.66l-32-32a4,4,0,0,1,0-5.66l32-32a4,4,0,0,1,5.66,5.66L97.66,124H168A4,4,0,0,1,172,128Z"></path></svg>',
        classes: {
            button: "f-button !w-14 !h-14 disabled:!opacity-70 !mx-4",
        },
    },
};

new Carousel(container, options);

const containerOffer = document.getElementById("offersCarousel");

const optionsOffer = {
    transition: 'slide',
    infinite: false,
    Dots: false,
    Navigation: {
        nextTpl: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" class="!w-14 !h-14 !fill-white"><path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm42.83-94.83a4,4,0,0,1,0,5.66l-32,32a4,4,0,0,1-5.66-5.66L158.34,132H88a4,4,0,0,1,0-8h70.34L133.17,98.83a4,4,0,0,1,5.66-5.66Z"></path></svg>',
        prevTpl: '<svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 256 256" class="!w-14 !h-14 !fill-white"><path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H97.66l25.17,25.17a4,4,0,0,1-5.66,5.66l-32-32a4,4,0,0,1,0-5.66l32-32a4,4,0,0,1,5.66,5.66L97.66,124H168A4,4,0,0,1,172,128Z"></path></svg>',
        classes: {
            button: "f-button !w-14 !h-14 disabled:!opacity-70 !mx-4",
        },
    },
};

new Carousel(containerOffer, optionsOffer);

Fancybox.bind('[data-fancybox]', {
    // Custom options for all galleries
});

const containers = document.getElementsByClassName("accommodationsCarousel");

const optionss = {
    infinite: false,
    Dots: false,
    Navigation: {
        nextTpl: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" class="!w-14 !h-14 !fill-white"><path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm42.83-94.83a4,4,0,0,1,0,5.66l-32,32a4,4,0,0,1-5.66-5.66L158.34,132H88a4,4,0,0,1,0-8h70.34L133.17,98.83a4,4,0,0,1,5.66-5.66Z"></path></svg>',
        prevTpl: '<svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 256 256" class="!w-14 !h-14 !fill-white"><path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H97.66l25.17,25.17a4,4,0,0,1-5.66,5.66l-32-32a4,4,0,0,1,0-5.66l32-32a4,4,0,0,1,5.66,5.66L97.66,124H168A4,4,0,0,1,172,128Z"></path></svg>',
        classes: {
            button: "f-button !w-14 !h-14 disabled:!opacity-70",
        },
    },
};

Array.from(containers).forEach(container => {
    new Carousel(container, optionss);
});